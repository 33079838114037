import gql from "graphql-tag";

export const updateWaybillSignatureMutation = gql`
  mutation UpdateWaybillSignatureMutation($waybillSignature: WaybillSignatureInput) {
    updateWaybillSignature(waybillSignature: $waybillSignature) {
      routeId
      waybillNumber
      customerNumber
      orderId
      driverNameClarification
      driverSignature
      driverTimestamp
      senderNameClarification
      senderSignature
      senderTimestamp
      receiverNameClarification
      receiverSignature
      receiverTimestamp
      senderSignatureNotAvailable
      receiverSignatureNotAvailable
      contractorId
      actualPickupStartTime
      actualPickupEndTime
      actualUnloadStartTime
      actualUnloadEndTime
    }
  }
`;
