import gql from "graphql-tag";

export const getWaybillSignatureQuery = gql`
  query GetWaybillSignature($routeId: ID!, $waybillNumber: ID!) {
    getWaybillSignature(routeId: $routeId, waybillNumber: $waybillNumber) {
      routeId
      waybillNumber
      customerNumber
      orderId
      driverSignature
      driverNameClarification
      driverTimestamp
      senderSignature
      senderNameClarification
      senderTimestamp
      receiverSignature
      receiverNameClarification
      receiverTimestamp
      senderSignatureNotAvailable
      receiverSignatureNotAvailable
      contractorId
      actualPickupStartTime
      actualPickupEndTime
      actualUnloadStartTime
      actualUnloadEndTime
    }
  }
`;

