import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { navigate } from "@reach/router";
import { ApolloConsumer, withApollo } from "react-apollo";

import getTimeString from "../../../utils/getTimeString";
import styles from "./DestinationFooter.module.scss";
import Button from "../../input/Button";
import LogExceptionButton from "../../exceptions/LogExceptionButton";
import WaybillSignatureModal from "../order/WaybillSignatureModal";
import { WaybillDataShape } from "../order/shapes";
import { getWaybillSignatureQuery } from '../../../api/graphql/getWaybillSignature';
import { getWaybillPdfQuery } from '../../../api/graphql/getWaybillPdf';
import { getExceptionsQuery } from '../../../api/graphql/getExceptions';

import fetchTransactionInputs from '../../../utils/fetchTransactionInputs';
import updateTransactionInput from "../../../utils/updateTransactionInput";
import fetchDestinationOrderCustomer from "../../../utils/fetchDestinationOrderCustomer";
import { isWaybillSigned, isSet } from '../utils/waybills';
import sendMessageToNativeApp from "../../../utils/sendMessageToNativeApp";

import { getOrderQuery } from "../../../api/graphql/getOrder";
import { logEvent } from "../../../api/graphql/logEvent";
import getUniqueProps from "../../../utils/getUniqueProps";
import { getImages } from "../../../utils/db";
import { sendEmailMutation } from "../../../api/graphql/sendEmail";

const getExceptions = async (appSyncClient, routeId, orderId) => {
  if (!routeId || !orderId) {
    console.log('need routeId and orderId!');
    return undefined;
  }

  try {
    const response = await appSyncClient.query({
      query: getExceptionsQuery,
      variables: { routeId, orderId },
      fetchPolicy: 'network-only'
    });
    return response.data.getExceptions;
  } catch (error) {
    console.log('Query error', JSON.stringify(error, null, 2));
  }
};

const sendEmailPdf = async (appSyncClient, waybillPdfResult) => {
  try {
    const input = waybillPdfResult.map(waybillPdfResult => ({
      presignedUrl: waybillPdfResult.presignedUrl,
      waybillNumber: waybillPdfResult.waybillNumber,
      customerEmail: waybillPdfResult.customerEmail,
      pickupEmail: waybillPdfResult.pickupEmail,
      unloadEmail: waybillPdfResult.unloadEmail,
      date: waybillPdfResult.date,
      waybillSigned: waybillPdfResult.waybillSigned
    }));

    const response = await appSyncClient.mutate({
      mutation: sendEmailMutation,
      variables: { input },
      fetchPolicy: 'no-cache'
    });
    return response.data.sendEmail;
  } catch (err) {
    console.error('sendEmail error', JSON.stringify(err, null, 2));
  }
};

const logToBackend = async (appSyncClient, message) => {
  try {
    await appSyncClient.mutate({
      mutation: logEvent,
      variables: {
        message: message
      },
    });
  } catch (error) {
    console.log("Error logging", error);
  }
}

const getWaybillSignature = async (appSyncClient, routeId, waybillNumber) => {
  if (!routeId || !waybillNumber) {
    console.log('need routeId and waybillNumber!');
    return undefined;
  }

  try {
    const response = await appSyncClient.query({
      query: getWaybillSignatureQuery,
      variables: { routeId, waybillNumber },
      fetchPolicy: 'network-only'
    })
    return response.data.getWaybillSignature ?? {};
  } catch (error) {
    console.log('Query error', JSON.stringify(error, null, 2));
  }
};

const getWaybillPdf = async (appSyncClient, orderData, setSigned) => {
  console.log('getWaybillPdf, orderData:', JSON.stringify(orderData, null, 2));

  try {
    const response = await appSyncClient.query({
      query: getWaybillPdfQuery,
      variables: { orderData: JSON.stringify(orderData) },
      fetchPolicy: 'no-cache'
    })
    console.log('getWaybillPdf response', JSON.stringify(response, null, 2));
    setSigned(true);
    return response.data.getWaybillPdf;
  } catch (error) {
    console.log('Query error', JSON.stringify(error, null, 2));
  }
};

const handleSignatures = (signatures, type, transactions, setSigned) => {
  const signed = signatures ? isWaybillSigned(signatures, type) : false;

  setSigned(signed);

  // In multiple-order case, we need to filter signatures by order or waybill. Signatures input value may be null.
  if (signatures) {
    if (transactions && type === 'load') {
      const driverSignatureIsSet = isSet(signatures?.driverSignature);
      const senderSignatureIsSet = isSet(signatures?.senderSignature) || (isSet(signatures?.senderSignatureNotAvailable) && signatures.senderSignatureNotAvailable);
      transactions.filter(transaction => transaction.orderNum === signatures.orderId).forEach(transaction =>
        updateTransactionInput(transaction.pickupTransactionId, {
          "senderNameClarification": signatures?.senderNameClarification,
          "senderTimestamp": signatures?.senderTimestamp,
          "senderSignatureNotAvailable": signatures?.senderSignatureNotAvailable,
          "driverNameClarification": signatures?.driverNameClarification,
          "driverTimestamp": signatures?.driverTimestamp,
          "signed": driverSignatureIsSet && senderSignatureIsSet,
          "signedOnLoad": driverSignatureIsSet && senderSignatureIsSet,
        }));
    } else if (transactions && type === 'unload') {
      const receiverSignatureIsSet = isSet(signatures?.receiverSignature) || (isSet(signatures?.receiverSignatureNotAvailable) && signatures.receiverSignatureNotAvailable);
      transactions.filter(transaction => transaction.orderNum === signatures.orderId).forEach(transaction =>
        updateTransactionInput(transaction.unloadTransactionId, {
          "receiverNameClarification": signatures?.receiverNameClarification,
          "receiverTimestamp": signatures?.receiverTimestamp,
          "receiverSignatureNotAvailable": signatures?.receiverSignatureNotAvailable,
          "signed": receiverSignatureIsSet,
          "signedOnUnload": receiverSignatureIsSet,
        }));
    }
  }
}

const getElapsed = (timestamp) => {
  if (!timestamp) return null;

  const hours = moment().diff(moment(timestamp, "DD-MM-YYYY HH:mm"), "hours");
  const minutes = moment().diff(moment(timestamp, "DD-MM-YYYY HH:mm"), "minutes") % 60;

  return `${hours} h ${minutes} min`;
}

const base64ToBlob = (base64, mimeType) => {
  const byteCharacters = atob(base64.split(',')[1]);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
}

const openPdfInNewTab = (base64String) => {
  if (!base64String) {
    console.log('PDF data not found');
    return;
  }

  if (typeof base64String !== 'string') {
    console.error('Invalid base64 string', base64String);
    return;
  }

  if (!base64String.startsWith('data:application/pdf;base64,')) {
    base64String = 'data:application/pdf;base64,' + base64String;
  }

  const isNativeApp = !!window.isNativeApp && window.ReactNativeWebView;
  if (isNativeApp) {
    sendMessageToNativeApp({
      operation: 'openPdf',
      data: {
        pdfContent: base64String
      }
    });
  } else {
    const pdfBlob = base64ToBlob(base64String, 'application/pdf');
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, '_blank');
  }
}

const DestinationFooter = ({
  id,
  ata,
  atd,
  eta,
  etd,
  state,
  type,
  allowActions,
  allowExceptions,
  currentVehicle,
  client,
  destinationId,
  onAction,
  orderView, // boolean, indicates if we are in order view -> where we can ask for signatures and download waybill pdf
  isLoading,
  newWaybillNumber,
  routeId,
  refreshRoutes,
  waybillData,
  actualPickupStartTime,
  actualPickupEndTime,
  actualUnloadStartTime,
  actualUnloadEndTime,
}) => {

  const [actionButtonHidden, setActionButtonHidden] = useState(true);
  const [actionButtonDisabled, setActionButtonDisabled] = useState(false);
  const [openUnsentWaybillPhotosWarning, setOpenUnsentWaybillPhotosWarning] = useState(false);
  const [signed, setSigned] = useState(false);
  const [waybillNumber, setWaybillNumber] = useState(newWaybillNumber);

  useEffect(async () => {
    const signedWithSignatureData = await resolveWaybillNumberAndSignatures();

    // if we have already determined that the waybill is signed, we don't need to check it again
    if (!signedWithSignatureData) {
      const transactionLoadInputs = fetchTransactionInputs();
      const transactionId = waybillData.transactions
        .map((transaction) => {
          if (orderView) {
            return type === 'load' ? transaction.pickupTransactionId : transaction.unloadTransactionId;
          }
          return transaction.transactionId;
        })
        .filter(id => id !== undefined);
      // Transactions may be from different orders but should be of same type, either load/unload.
      // Activate only if all of them are signed even if not displayed (single order view).
      const isSigned = waybillData.transactions.every(t => transactionLoadInputs[transactionId]?.signed ?? false);
      setSigned(isSigned);
    }
    resolveActionButtonDisabled();
  }, []);

  useEffect(() => {
    resolveActionButtonDisabled();
  }, [isLoading, state, signed, waybillNumber]);


  const resolveWaybillNumberAndSignatures = async () => {
    // we need to fetch the signatures to know whether the waybill is signed or not
    // so that we can enable/disable the waybill/exception buttons
    const transactionLoadInputs = fetchTransactionInputs();
    const transactionId = waybillData.transactions
      .map((transaction) => {
        if (orderView) {
          return type === 'load' ? transaction.pickupTransactionId : transaction.unloadTransactionId;
        }
        return transaction.transactionId;
      })
      .filter(id => id !== undefined);
    let signedWithSignatureData = false;

    if (waybillData?.transactions) {
      const waybillNumber = transactionLoadInputs[transactionId[0]]?.waybill;
      setWaybillNumber(waybillNumber);
      const signatures = await getWaybillSignature(client, routeId, waybillNumber);
      signedWithSignatureData = signatures ? isWaybillSigned(signatures, type) : false;
      handleSignatures(signatures, type, waybillData.transactions, () => { }); // do not modify signed state here
    } else {
      // multiple orders, rahtikirja button should not be active so we should also not have waybill number
      setWaybillNumber(undefined);
    }

    return signedWithSignatureData;
  }

  const resolveActionButtonDisabled = async () => {
    console.log(`resolveActionButtonDisabled(), type: ${type}, state: ${state}, signed: ${signed}, waybillNumber: ${waybillNumber}`);

    if (!waybillNumber && state === "ongoing" && !signed) {
      console.log('WaybillNumber missing, try to resolve');
      const signedWithSignatureData = await resolveWaybillNumberAndSignatures();
      setSigned(signedWithSignatureData);
      return; // signed chaged, useEffect will be called again and will call this function again (hopefully with waybillNumber)
    }

    switch (state) {
      case "new":
        break;
      case "updated":
        break;
      case "cancelled":
        break;
      case "completed":
        setActionButtonHidden(signed);
        break;
      case "ongoing":
        if (type === 'unload' && !orderView) {
          // Allow unload ending only in order-level
          setActionButtonHidden(true);
          break;
        }
        if (!signed) {
          console.warn('Allekirjoitukset puuttuvat');
        }
        setActionButtonHidden(!signed);
        break;
      default:
        // state is null, when load/unload hasn't been started yet
        if (type === 'unload') {
          // User should not be able to start unloading if loading has not finished.
          // The problem is just that we are down the wrong tree, different destination,
          // and have to fetch information about the pickuptransactions for the same order(s).
          // Check this from transactions array because this.props.orderNum is
          // only set in detailed single order view. It would be null in destination view.
          // Also waybillData would be null.
          const promises = [];
          let orderNums = []
          if (orderView) {
            orderNums.push(waybillData.orderId)
          }
          else {
            orderNums = getUniqueProps(waybillData.transactions, "orderNum").filter(num => num !== undefined); // filtering !!num did not work???
          }
          try {
            orderNums.forEach((orderNum) => {
              const orderQuery = client.query({
                query: getOrderQuery,
                variables: { orderId: orderNum },
                fetchPolicy: 'network-only'
              });
              promises.push(orderQuery);
            });
          } catch (error) {
            console.error('Error prefetching order', JSON.stringify(error, null, 2));
          }
          Promise.all(promises).then(values => {
            const flags = values.map(response => {
              const orderData = response.data.getOrder;
              if (!orderData || !Array.isArray(orderData.rows) || !orderData.rows.length) {
                return false;
              }
              console.log('Rows', orderData.rows);
              if (!Array.isArray(orderData.rows[0].transactions) || !orderData.rows[0].transactions.length) {
                return false;
              }
              return orderData.rows[0].transactions[0].actualPickupEndTime !== null ?? false;
            });

            const isAllLoaded = flags.every(value => value);
            if (!isAllLoaded) {
              console.warn('Purkua ei voi aloittaa ennen kuin lastaus on päättynyt kaikille tilauksille:', orderNums);
            }
            setActionButtonHidden(!isAllLoaded);
          });
        } else {
          setActionButtonHidden(false);
        }
        break;
    }
  }

  const createOrderData = async (waybillSignature, exceptions, preview, downloadIfExists = false) => {
    const images = waybillData.orderId ? await getImages(destinationId, waybillData.orderId) : [];
    return {
      downloadIfExists: downloadIfExists,
      preview: preview,
      customer: {
        id: waybillData.customerNum,
        name: waybillData.customerName,
        address: {
          streetAddress: waybillData.rows[0].transactions[0].pickupAddress,
          postCode: waybillData.rows[0].transactions[0].pickupZipcode,
          city: waybillData.rows[0].transactions[0].pickupCity,
        },
        contactPerson: waybillData.rows[0].transactions[0].pickupPerson,
        phoneNumber: waybillData.rows[0].transactions[0].pickupPhone,
        emailAddress: waybillData.rows[0].transactions[0].pickupEmail,
      },
      customerData: {
        id: waybillData.customerData.id,
        name: waybillData.customerData.name,
        address: waybillData.customerData.address,
        zipCode: waybillData.customerData.zipCode,
        city: waybillData.customerData.city,
        businessId: waybillData.customerData.businessId,
        contactPersons: waybillData.customerData.contactPersons,
        phone: waybillData.customerData.phone,
        email: waybillData.customerData.email,
        name2: waybillData.customerData.name2
      },
      contractorData: {
        id: waybillData.contractorData.id,
        name: waybillData.contractorData.name,
        phone: waybillData.contractorData.phone,
        email: waybillData.contractorData.email,
        businessId: waybillData.contractorData.businessId,
        company: waybillData.contractorData.company,
        address: waybillData.contractorData.address,
        zipCode: waybillData.contractorData.zipCode,
        city: waybillData.contractorData.city
      },
      payer: {
        id: waybillData.payerNum,
        name: waybillData.payerName,
        address: {
          streetAddress: waybillData.rows[0].transactions[0].unloadAddress,
          postCode: waybillData.rows[0].transactions[0].unloadZipcode,
          city: waybillData.rows[0].transactions[0].unloadCity,
        },
        contactPerson: waybillData.rows[0].transactions[0].unloadPerson,
        phoneNumber: waybillData.rows[0].transactions[0].unloadPhone,
        emailAddress: waybillData.rows[0].transactions[0].unloadEmail,
      },
      rawSisuOrder: {
        orderNum: waybillData.orderId,
        ...waybillData
      },
      signatures: {
        transport: {
          imgData: waybillSignature?.driverSignature,
          nameClarification: waybillSignature?.driverNameClarification,
          timestamp: waybillSignature?.driverTimestamp
        },
        consignor: {
          imgData: waybillSignature?.senderSignature,
          nameClarification: waybillSignature?.senderNameClarification,
          timestamp: waybillSignature?.senderTimestamp,
          senderSignatureNotAvailable: waybillSignature?.senderSignatureNotAvailable,
        },
        consignee: {
          imgData: waybillSignature?.receiverSignature,
          nameClarification: waybillSignature?.receiverNameClarification,
          timestamp: waybillSignature?.receiverTimestamp,
          receiverSignatureNotAvailable: waybillSignature?.receiverSignatureNotAvailable,
        },
        actualPickupStartTime: waybillSignature?.actualPickupStartTime,
        actualPickupEndTime: waybillSignature?.actualPickupEndTime,
        actualUnloadStartTime: waybillSignature?.actualUnloadStartTime,
        actualUnloadEndTime: waybillSignature?.actualUnloadEndTime,
      },
      exceptions: exceptions ?? [],
      waybillImages: images ? images.filter(img => img !== "" && img !== null) : [],
      licenseNum: currentVehicle?.licenseNum ?? "",
      operatorName: currentVehicle?.contractorName ?? "",
      destination: fetchDestinationOrderCustomer(destinationId)
    };
  }

  const onOpenUnsentWaybillPhotosModal = () => {
    // only open when unloading
    waybillData?.isOngoingUnload === true && setOpenUnsentWaybillPhotosWarning(true);
  };

  const needToSendWaybillPhotos = async () => {
    if (!waybillData?.isOngoingUnload) {
      return false;
    }
    const images = await getImages(destinationId, waybillData?.orderId);
    const filteredImages = images ? images.filter(img => img !== "" && img !== null) : [];
    return filteredImages.length === 0;
  };

  const elapsed = getElapsed(ata);
  const etaDate = moment(eta, "DD-MM-YYYY HH:mm");
  const etaString = getTimeString(etaDate);
  const etdDate = moment(etd, "DD-MM-YYYY HH:mm");
  const etdString = getTimeString(etdDate);
  const ataDate = moment(ata, "DD-MM-YYYY HH:mm");
  const ataString = getTimeString(ataDate);
  const atdDate = moment(atd, "DD-MM-YYYY HH:mm");
  const atdString = getTimeString(atdDate);

  return (
    <footer className={`${styles.footer} ${styles[state]}`}>
      <Modal open={openUnsentWaybillPhotosWarning} onClose={() => setOpenUnsentWaybillPhotosWarning(false)}>
        <div className={styles.state}>
          <span>Kuljetusasiakirjakuvat</span>
        </div>
        <div className={styles.confirmation}>
          <p>Jatketaanko lähettämättä kuljetusasiakirjakuvia?</p>
          <Button disabled={!signed} onClick={async () => {
            refreshRoutes();
            onAction();
            await logToBackend(client, `orderNum: ${waybillData?.orderId}, Waybill was saved with no images, waybillNumber: ${waybillNumber}`);
            const exceptions = await getExceptions(client, routeId, waybillData?.orderId);
            const waybillSignature = await getWaybillSignature(client, routeId, waybillNumber);
            // Create both, preview and final waybill PDFs.
            // ** ** Why do we have to create the preview PDF here again? We also create it at waybillSignatureModals onSave() -method ** **
            const orderData = await createOrderData(waybillSignature, exceptions, true, false);
            await getWaybillPdf(client, orderData, () => setSigned);
            const orderDataFinal = await createOrderData(waybillSignature, exceptions, false, false);
            await logToBackend(client, `orderNum: ${waybillData?.orderId}, about to create final PDF from DestinationFooter, waybillNumber: ${waybillNumber}`);
            const waybillPdfResponse = await getWaybillPdf(client, orderDataFinal, () => setSigned);
            if (waybillPdfResponse && waybillPdfResponse.results.length > 0) {
              await sendEmailPdf(client, waybillPdfResponse.results);
            }
            setOpenUnsentWaybillPhotosWarning(false);
          }}>
            Kyllä
          </Button>
          <Button onClick={() => setOpenUnsentWaybillPhotosWarning(false)}>Ei</Button>
        </div>
      </Modal>
      {state === "ongoing" ? (
        <span className={styles.time}>
          <span>
            <span className={styles.label}>Aikaa mennyt</span>
            {elapsed}
          </span>
        </span>
      ) : state === "completed" ? (
        <span className={styles.time}>
          <span>
            <span className={styles.label}>
              {type === "load" ? "Lastattu" : "Purettu"} {atdDate.format("D.M.YYYY")}
            </span>
            {`${ataString} –  ${atdString}`}
          </span>
          <span>
            <span className={styles.label}>{type === "load" ? "Lastauksen kesto" : "Purun kesto"}</span>
            {`${atdDate.diff(moment(ataDate, "DD-MM-YYYY HH:mm"), "hours")} h ${
              atdDate.diff(moment(ataDate, "DD-MM-YYYY HH:mm"), "minutes") % 60
            } min`}
          </span>
        </span>
      ) : (
        <span className={styles.time}>{`${etaString}${eta !== etd ? ` –  ${etdString}` : ""}`}</span>
      )}
      <div className={styles.buttonContainer}>
        <span className={styles.label}>
          {type === "unload" && state === "ongoing"
            ? "HUOM! Allekirjoita ja ota kuljetusasiakirjakuvat ennen purun lopetusta"
            : ""}
        </span>
        {/*
          * Waybill pdf is possible to download only in orderview and
          * - always if state is completed ie. we are finished loading / unloading
          * - in loading only after signatures are given
          * - in unloading always in orderview
        */}
        {((state === "completed" && orderView)
          || (type === "load" && signed && state === "ongoing" && orderView)
          || (type === "unload" && state && orderView)) && (
            <Button onClick={async () => {
              refreshRoutes();
              const exceptions = await getExceptions(client, routeId, waybillData?.orderId);
              const waybillSignature = await getWaybillSignature(client, routeId, waybillNumber);
              const orderData = await createOrderData(waybillSignature, exceptions, false, true);
              const waybillPdfResult = await getWaybillPdf(client, orderData, () => setSigned);
              openPdfInNewTab(waybillPdfResult.storedPdfBuffer);
            }}>
              Rahtikirja
            </Button>
          )}
        {state === "ongoing" && orderView && (
          <ApolloConsumer>
            {(apolloClient) => (
              <WaybillSignatureModal
                apolloClient={apolloClient}
                createOrderData={createOrderData}
                destinationId={destinationId}
                getExceptions={(routeId, waybillNumber) => getExceptions(client, routeId, waybillNumber)}
                getWaybillPdf={(data) => getWaybillPdf(client, data, () => setSigned)}
                getWaybillSignature={(routeId, waybillNumber) => getWaybillSignature(client, routeId, waybillNumber)}
                signed={signed}
                onSignaturesChange={(signatures, type, transactions) => handleSignatures(signatures, type, transactions, setSigned)}
                refreshRoutes={refreshRoutes}
                type={type}
                waybillData={waybillData}
                waybillNumber={waybillNumber}
                actualPickupStartTime={actualPickupStartTime}
                actualPickupEndTime={actualPickupEndTime}
                actualUnloadStartTime={actualUnloadStartTime}
                actualUnloadEndTime={actualUnloadEndTime}
              />
            )}
          </ApolloConsumer>
        )}
        {/* logging exceptions not allowed after signing */}
        {allowExceptions && state && (
          <LogExceptionButton
            disabled={signed}
            onClick={() => {
              navigate(`/routes/exception/${id}`);
              window.location.reload();
            }}
          >
            Poikkeama
          </LogExceptionButton>
        )}
        {allowActions && state !== "completed" && (
          <Button
            onClick={async () => {
              setActionButtonDisabled(true);
              await logToBackend(client, `orderNum: ${waybillData?.orderId}, waybillNumber: ${waybillNumber}, type: ${type}, state: ${state}. unload/load button onClick`);
              if (type === "unload" && state === "ongoing") {
                if (await needToSendWaybillPhotos()) {
                  await logToBackend(client, `orderNum: ${waybillData?.orderId}, waybillNumber: ${waybillNumber}. unload: needToSendWaybillPhotos`);
                  onOpenUnsentWaybillPhotosModal();
                } else {
                  onAction();
                  await logToBackend(client, `orderNum: ${waybillData?.orderId}, waybillNumber: ${waybillNumber}, unload: executed onAction`);
                  const exceptions = await getExceptions(client, routeId, waybillData?.orderId);
                  await logToBackend(client, `orderNum: ${waybillData?.orderId}, waybillNumber: ${waybillNumber}, unload: exceptions fetched`);
                  const waybillSignature = await getWaybillSignature(client, routeId, waybillNumber);
                  await logToBackend(client, `orderNum: ${waybillData?.orderId}, waybillNumber: ${waybillNumber}, unload: waybill signature fetched`);
                  // Create both, preview and final waybill PDFs
                  // ** ** Why do we have to create the preview PDF here again? We also create it at waybillSignatureModals onSave() -method ** **
                  const orderData = await createOrderData(waybillSignature, exceptions, true, false);
                  await logToBackend(client, `orderNum: ${waybillData?.orderId}, waybillNumber: ${waybillNumber}, unload: about to create preview`);
                  await getWaybillPdf(client, orderData, () => setSigned);
                  const orderDataFinal = await createOrderData(waybillSignature, exceptions, false, false);
                  await logToBackend(client, `orderNum: ${waybillData?.orderId}, about to create final PDF from DestinationFooter, waybillNumber: ${waybillNumber}`);
                  const waybillPdfResult = await getWaybillPdf(client, orderDataFinal, () => setSigned);
                  if (waybillPdfResult && waybillPdfResult.results.length > 0) {
                    await sendEmailPdf(client, waybillPdfResult.results);
                  }
                }
              } else {
                onAction();
              }
              setActionButtonDisabled(false);
            }}
            isLoading={isLoading}
            hidden={actionButtonHidden}
            disabled={actionButtonDisabled || (!waybillNumber && state === "ongoing") || (state === "ongoing" && signed === false)}>
              {state === "ongoing"
                ? type === "load"
                  ? "Lopeta lastaus"
                  : "Lopeta purku"
                : type === "load"
                  ? "Aloita lastaus"
                  : "Aloita purku"}
          </Button>
        )}
      </div>
    </footer>
  );
}

DestinationFooter.propTypes = {
  allowActions: PropTypes.bool.isRequired,
  allowExceptions: PropTypes.bool,
  currentVehicle: PropTypes.object,
  ata: PropTypes.string,
  atd: PropTypes.string,
  eta: PropTypes.string.isRequired,
  etd: PropTypes.string,
  state: PropTypes.oneOf(["new", "updated", "cancelled", "completed", "ongoing"]),
  type: PropTypes.oneOf(["load", "unload"]).isRequired,
  routeId: PropTypes.string,
  onAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isOnline: PropTypes.bool,
  newWaybillNumber: PropTypes.string,
  refreshRoutes: PropTypes.func.isRequired,
  waybillData: PropTypes.shape(WaybillDataShape),
};

export default withApollo(DestinationFooter);
