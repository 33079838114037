import gql from "graphql-tag";

export const getWaybillPdfQuery = gql`
  query GetWaybillPdf($orderData: AWSJSON) {
    getWaybillPdf(orderData: $orderData) {
      storedPdfBuffer
      results {
        presignedUrl
        waybillNumber
        customerEmail
        pickupEmail
        unloadEmail
        waybillSigned
        date
      }
    }
  }
`;
